import React, { useEffect, useContext, useState, useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Paper,
  Avatar,
  Chip
} from "@material-ui/core";
import Bvp from "./../images/bvp.jpg";
import clsx from "clsx";
import * as constants from "../constants";
import SidenavContext from "../utils/SidenavContext";
import Lightbox from "react-image-lightbox";
import AnimationContext from "../utils/AnimationContext";
import AnchorLink from "react-anchor-link-smooth-scroll";
import useScreensize from "../hooks/useScreensize";
import { grey } from "@material-ui/core/colors";
const isBrowser = typeof window !== `undefined`;

function getScrollPosition(element = null, useWindow = true) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    opacity: 0,
    transition: "opacity 1s",
    [theme.breakpoints.up("sm")]: {
      transition: "transform 1s, opacity 1s"
    }
  },
  isVisible: {
    opacity: 1,
    transform: "translateY(0)",
    position: "relative",
    zIndex: 1
  },
  isInvisibleFirst: {
    [theme.breakpoints.up("sm")]: {
      transform: "translateY(100px)"
    }
  },
  bg: {
    padding: theme.spacing(6),
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    position: "relative",
    transition: "margin 0.5s ease-out",
    maxWidth: "60vw",
    [theme.breakpoints.down("md")]: {
      transition: "padding 0.5s ease-out"
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%"
    }
  },
  bgImg: {
    backgroundSize: "cover",
    backgroundPosition: "center center",
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    transition: "all 0.1s ease-out",
    height: "100%",
    opacity: 1,
    [theme.breakpoints.down("sm")]: {
      borderRadius: theme.shape.borderRadius
    }
  },
  bgImgAnim: {
    opacity: 0
  },
  bgAnim: {
    marginTop: theme.spacing(-2),
    [theme.breakpoints.down("md")]: {
      padding: 0
    }
  },
  /*
  bgAnim: {
    position: "fixed",
    top: theme.spacing(-8),
    left: 0,
    right: 0,
    height: "100vh",
    zIndex: 999,
    paddingLeft: "50vw"
  },
  */
  paper: {
    transition: "all 0.5s",
    position: "relative",
    zIndex: 9999,
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    }
  },
  img: {
    marginTop: 0,
    transition: "margin 0.5s ease-out",
    cursor: "pointer"
  },
  imgAnim: {
    marginTop: theme.spacing(-6.5),
    marginRight: theme.spacing(-12),
    transition: "margin 0.2s ease-out",
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
      marginTop: 0
    }
  },
  paperAnim: {},
  bigBg: {
    backgroundSize: "cover",
    backgroundPosition: "center center",
    opacity: 0,
    width: "100%",
    height: "100%",
    position: "fixed",
    left: 0,
    top: 0,
    transition: "all 0.5s ease-out",
    pointerEvents: "none",
    zIndex: 9999
  },
  bigBgAnim: {
    opacity: 1
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  },
  testimonial: {
    position: "relative",
    opacity: 0,
    transition: "all 0.5s ease-out",
    transitionDelay: "display 0s",
    left: 0,
    marginTop: -300,
    zIndex: 999
  },
  testimonialAnim: {
    transitionDelay: "display 0.5s",
    opacity: 1,
    left: 0,
    marginTop: theme.spacing(4)
  },
  pWrapper: {
    transition: "padding 0.5s ease-out, transform 0.5s ease-out",
    transitionDelay: "0.3s",
    position: "relative",
    left: 0,
    top: 0,
    right: 0,
    [theme.breakpoints.up("lg")]: {
      maxWidth: "100vw",
      overflow: "hidden",
      height: "40vw",
      maxHeight: "40vw"
    },
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(15)
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(5)
    }
  },
  pWrapperAnim: {
    height: "100vh",
    maxHeight: "100vh",
    zIndex: 999999,
    paddingLeft: theme.spacing(5),
    overflowY: "auto",
    marginBottom: theme.spacing(0),
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh + 4px)",
      paddingLeft: 0
    },
    [theme.breakpoints.only("md")]: {
      paddingBottom: theme.spacing(4)
    }
  },
  anchor: {
    position: "absolute",
    display: "block",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 99
  },
  chip: {
    textTransform: "uppercase",
    fontWeight: "bold",
    color: grey[600],
    marginRight: theme.spacing(1)
  }
}));

const Projekt = ({ isVisible, item, count }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const classes = useStyles();
  const {
    currentSidenavItem,
    currentSidenavBecameInactive,
    currentSidenavBecameActive
  } = useContext(SidenavContext);
  const { isAnimated, setIsAnimated } = useContext(AnimationContext);
  const { xs, sm, md, lg, xl } = useScreensize();
  const refContainer = useRef(null);
  const [scrollOffset, setScrollOffset] = useState(0);

  useEffect(() => {
    if (isVisible) {
      if (!show) {
        setShow(true);
      }
      currentSidenavBecameActive("#projekte_" + item.title);
    } else {
      currentSidenavBecameInactive("#projekte_" + item.title);
    }
  }, [isVisible]);

  /*
  useEffect(() => {
    if (isAnimated === item._id) {
      const sE = getScrollPosition(refContainer, false);
      console.log(getScrollPosition());
      console.log(refContainer);
      console.log(getScrollPosition(refContainer, false));
      setScrollOffset(sE.y * -1);
      console.log("offs", sE.y * -1);
    }
  }, [isAnimated]);
  */

  const createMarkup = html => {
    return { __html: html };
  };

  const toggleAnimated = () => {
    if (isAnimated === item._id) {
      setIsAnimated(null);
      return;
    }

    const sE = getScrollPosition(refContainer, false);
    const sW = getScrollPosition();
    setScrollOffset(Math.round(sE.y * -1));
    setIsAnimated(item._id);
  };

  const pIsAnimated = isAnimated === item._id;

  return (
    <>
      <Box
        onClick={() => {
          toggleAnimated();
        }}
        ref={refContainer}
        pl={{ xs: 0, lg: 50, xl: 80 }}
        pb={{ xs: 2, md: 10 }}
        pt={{ xs: 2, md: 10 }}
        pr={{ xs: 0, lg: 15 }}
        style={{
          transform:
            "translateY(" + (pIsAnimated ? scrollOffset + "px" : 0) + ")"
        }}
        className={clsx(classes.pWrapper, pIsAnimated && classes.pWrapperAnim)}
      >
        <div>
          <Box
            id={"projekt_" + item._id}
            className={clsx(
              classes.wrapper,
              show &&
                (!isAnimated || isAnimated === item._id) &&
                classes.isVisible,
              !show && classes.isInvisibleFirst
            )}
          >
            <Box
              display="flex"
              flexDirection={xs || sm ? "column-reverse" : "row"}
            >
              <Box width={xs || sm ? "100%" : 500} flexShrink={0}>
                <Paper
                  elevation={pIsAnimated ? 5 : 0}
                  className={clsx(
                    classes.paper,
                    pIsAnimated && classes.paperAnim
                  )}
                >
                  <Box p={{ xs: 2, md: 4 }}>
                    <Typography variant="h3" color="textSecondary">
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="textSecondary"
                    >
                      <div
                        dangerouslySetInnerHTML={createMarkup(item.description)}
                      />
                    </Typography>
                    {item.technology &&
                      pIsAnimated &&
                      item.technology.map((tech, i) => (
                        <Chip
                          key={i}
                          label={tech}
                          variant="outlined"
                          className={classes.chip}
                        />
                      ))}
                  </Box>
                </Paper>
                <Paper
                  elevation={pIsAnimated ? 5 : 0}
                  className={clsx(
                    classes.paper,
                    classes.testimonial,
                    pIsAnimated && classes.paperAnim,
                    pIsAnimated && classes.testimonialAnim
                  )}
                >
                  <Box p={{ xs: 2, md: 4 }}>
                    <Box display="flex">
                      {item.testimonial_image && (
                        <Avatar
                          className={classes.avatar}
                          alt={item.testimonial_name}
                          src={
                            constants.BACKEND_URL +
                            "/" +
                            item.testimonial_image.path
                          }
                        />
                      )}
                      <Box ml={3}>
                        <Typography variant="h5" color="textSecondary">
                          {item.testimonial_name}
                        </Typography>
                        <Typography variant="overline" color="textSecondary">
                          {item.testimonial_position}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      variant="body2"
                      component="div"
                      color="textSecondary"
                    >
                      <div
                        dangerouslySetInnerHTML={createMarkup(
                          item.testimonial_text
                        )}
                      />
                    </Typography>
                  </Box>
                </Paper>
              </Box>
              <Box
                width={xs || sm ? "100%" : undefined}
                flexGrow={1}
                ml="auto"
                display="flex"
                justifyContent="flex-end"
              >
                <Box p={2}>
                  {item.image &&
                    item.background &&
                    item.image.path &&
                    item.background.path && (
                      <div
                        className={clsx(
                          classes.bg,
                          pIsAnimated && classes.bgAnim
                        )}
                      >
                        <div
                          className={clsx(
                            classes.bgImg,
                            pIsAnimated && classes.bgImgAnim
                          )}
                          style={{
                            backgroundImage:
                              "url(" +
                              constants.BACKEND_URL +
                              "/" +
                              item.background.path +
                              ")"
                          }}
                        ></div>
                        <img
                          className={clsx(
                            classes.img,
                            pIsAnimated && classes.imgAnim
                          )}
                          src={constants.BACKEND_URL + "/" + item.image.path}
                          alt={item.title}
                          style={{
                            width: "100%",
                            height: "auto",
                            zIndex: 9
                          }}
                        />
                      </div>
                    )}
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </Box>
      {item.image &&
        item.background &&
        item.image.path &&
        item.background.path && (
          <div
            style={{
              backgroundImage:
                "url(" +
                constants.BACKEND_URL +
                "/" +
                item.background.path +
                ")"
            }}
            className={clsx(classes.bigBg, pIsAnimated && classes.bigBgAnim)}
          ></div>
        )}
    </>
  );
};

export default Projekt;
