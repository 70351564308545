import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  Toolbar,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grow,
  Container,
  Box
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../images/logo_wamedia.png";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { useTheme } from "@material-ui/styles";
import * as constants from "../constants";
import API from "../utils/API";
import KaSeo from "./shared/KaSeo";
import useScreensize from "../hooks/useScreensize";
import MenuUpIcon from "mdi-react/MenuUpIcon";
import { grey } from "@material-ui/core/colors";
import clsx from "clsx";
import Beratungstermin from "./shared/Beratungstermin";
import ScreenSize from "@bit/wamedia.tasso.screen-size";
import TrackVisibility from "react-on-screen";
import Burger from "@animated-burgers/burger-squeeze";
// don't forget the styles
import "@animated-burgers/burger-squeeze/dist/styles.css";
import AnimationContext from "../utils/AnimationContext";
import AnchorLink from "react-anchor-link-smooth-scroll";

const drawerWidth = 280;

const main_menu = [];

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      flexShrink: 0,
      zIndex: 9999999
    }
  },
  appBar: {
    position: "fixed",
    marginLeft: drawerWidth,
    zIndex: 999,
    transition: "all 0.3s ease-in",
    [theme.breakpoints.down("md")]: {
      background: "#fff !important",
      zIndex: 99999
    }
  },
  appBarOut: {
    top: -68
  },
  menuButton: {
    color: blueGrey[800],
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    zIndex: 9999,
    width: drawerWidth,
    background: "white"
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  logo: {
    height: "100%",
    width: "auto"
  },
  logoWrapper: {
    height: "90%",
    marginRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    opacity: 1,
    transition: "all 0.3s ease-in",
    position: "relative",
    left: 0,
    [theme.breakpoints.only("md")]: {
      height: "57%",
      paddingTop: 1
    },
    [theme.breakpoints.down("sm")]: {
      top: theme.spacing(-1)
    }
  },
  logoWrapperOut: {
    [theme.breakpoints.up("lg")]: {
      opacity: 0,
      left: -400
    }
  },
  appBarMenu: {
    flexGrow: 1
  },
  tb: {
    height: 1
  },
  btnBeratung: {
    marginRight: "15px"
  },
  desktopMenuIcon: {
    marginLeft: "auto",
    marginRight: theme.spacing(2),
    opacity: 1,
    transition: "all 0.3s ease-in",
    position: "relative",
    left: 0
  },
  desktopMenuIconOut: {
    opacity: 0,
    left: 200
  },
  popover: {
    zIndex: 99999999,
    marginTop: theme.spacing(3)
  },
  popoverPaper: {
    background: grey[50],
    minWidth: 600
  },
  menuUpIcon: {
    position: "absolute",
    right: 0,
    top: -30,
    width: 50,
    height: 50,
    color: "white"
  },
  menuImage: {
    backgroundSize: "cover"
  },
  menuItem: {
    borderRadius: 4,
    color: grey[800] + " !important"
  },
  activeMenuItem: {
    background: grey[100]
  },
  menuItemStrong: {
    textTransform: "uppercase"
  }
}));

const Layout = ({ children, settings }) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [desktopOpen, setDesktopOpen] = useState(false);
  const theme = useTheme();
  const anchorRef = React.useRef(null);
  const { xs, sm, md, lg, xl } = useScreensize();
  const [activePath, setActivePath] = useState(null);
  const location = useLocation();
  const { isAnimated } = useContext(AnimationContext);

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDesktopClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setDesktopOpen(false);
  };

  const menu = Boolean(settings) && (
    <MenuList>
      {settings.main_menu.map((item, index) => (
        <MenuItem
          className={clsx(classes.menuItem, {
            [classes.activeMenuItem]: item.value.url === activePath
          })}
          button
          key={index}
          offset="60"
          component={AnchorLink}
          href={item.value.url}
          onClick={event => {
            setMobileOpen(false);
            handleDesktopClose(event);
          }}
        >
          <Box display="flex" py={1} alignItems="center">
            <Box
              mr={2}
              borderRadius={4}
              flexShrink={0}
              className={classes.menuImage}
              width={xs ? 40 : 80}
              height={xs ? 40 : 80}
              style={{
                backgroundImage:
                  "url(" +
                  constants.BACKEND_URL +
                  "/storage/uploads" +
                  item.value.image.path +
                  ")"
              }}
            ></Box>
            <Box flexGrow={1} p={1} lineHeight={1.3}>
              <strong className={classes.menuItemStrong}>
                {item.value.title}
              </strong>
              <br />
              {item.value.subtitle}
            </Box>
          </Box>
        </MenuItem>
      ))}
    </MenuList>
  );

  const secondaryMenu = Boolean(settings) && (
    <MenuList>
      {settings.secondary_menu.map((item, index) => (
        <MenuItem
          className={clsx(classes.menuItem, {
            [classes.activeMenuItem]: item.value.url === activePath
          })}
          button
          key={index}
          component={Link}
          to={item.value.url}
          onClick={event => {
            setMobileOpen(false);
            handleDesktopClose(event);
          }}
        >
          {item.value.title}
        </MenuItem>
      ))}
    </MenuList>
  );

  const drawer = Boolean(settings) && (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      {menu}
      <Divider />
      {secondaryMenu}
    </div>
  );

  const seo = Boolean(settings) && (
    <KaSeo title={settings.title} description={settings.description} />
  );

  // console.log(activePath);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{ backgroundColor: "transparent" }}
        className={clsx(
          classes.appBar,
          isAnimated && (xs || sm || md) && classes.appBarOut
        )}
        elevation={xs || sm || md ? 2 : 0}
      >
        <Toolbar className={classes.tb}>
          <Container maxWidth="xl">
            <Box display="flex" height={xs || sm ? 56 : 90} alignItems="center">
              {activePath === "/" && (
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <Burger isOpen={false} />
                </IconButton>
              )}

              <Link
                className={clsx(
                  classes.logoWrapper,
                  isAnimated && classes.logoWrapperOut
                )}
                to="/"
              >
                <img
                  src={Logo}
                  className={classes.logo}
                  alt="wamedia IT Concept Kaiserslautern"
                />
              </Link>
              {activePath === "/" && (
                <Hidden smDown>
                  <IconButton
                    className={clsx(
                      classes.desktopMenuIcon,
                      isAnimated && classes.desktopMenuIconOut
                    )}
                    ref={anchorRef}
                    onClick={() => {
                      setDesktopOpen(!desktopOpen);
                    }}
                  >
                    <Burger isOpen={desktopOpen} />
                  </IconButton>
                </Hidden>
              )}
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="Mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
      {desktopOpen && (
        <Popper
          className={classes.popover}
          open={desktopOpen}
          anchorEl={anchorRef.current}
          keepMounted
          transition
          disablePortal
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} in={desktopOpen}>
              <Paper id="menu-list-grow" className={classes.popoverPaper}>
                <MenuUpIcon className={classes.menuUpIcon} />
                <ClickAwayListener onClickAway={handleDesktopClose}>
                  <Box display="flex" p={4}>
                    <Box flexGrow={1} flexShrink={0} flexBasis="1px" mr={3}>
                      {menu || <></>}
                    </Box>
                    <Box width="1px" bgcolor={grey[200]} mr={3} />
                    <Box
                      flexGrow={1}
                      flexShrink={0}
                      flexBasis="1px"
                      px={2}
                      display="flex"
                      alignItems="flex-end"
                    >
                      {secondaryMenu || <></>}
                    </Box>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
      <TrackVisibility partialVisibility offset={-100}>
        <Footer
          contactInfo={settings && settings.contact_info}
          contactImage={settings && settings.contact_image}
        />
      </TrackVisibility>
      <Beratungstermin />
      {seo}
    </div>
  );
};

export default Layout;
